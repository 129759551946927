import Paper from './Paper';
import './BottomBar.sass';
import { IoLogoInstagram } from 'react-icons/io';

interface TopBarProps {
  children?: React.ReactNode | undefined;
}

export default function TopBar({ children }: TopBarProps) {
  return (
    <footer className="BottomBar">
      <Paper>
        <>{children}</>
      </Paper>
      <Paper className="socials">
        <a
          className="social"
          href="https://www.instagram.com/fkakkus/"
          target="_blank"
          rel="noreferrer"
        >
          <IoLogoInstagram />
        </a>
      </Paper>
    </footer>
  );
}
