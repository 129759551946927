import LoaderSpinner from 'react-loader-spinner';
import './Loader.sass';

interface LoaderProps {
  className?: string;
}

export default function Loader({ className }: LoaderProps) {
  return (
    <div className={'Loader' + (className ? ` ${className}` : '')}>
      <LoaderSpinner type="TailSpin" color="#00BFFF" />
    </div>
  );
}
