import React from 'react';
import './Paper.sass';

export interface PaperProps {
  children?: React.ReactNode | undefined;
  elevated?: boolean | undefined;
  className?: string | undefined;
}

export default function Paper({ children, className }: PaperProps) {
  return (
    <div className={'Paper' + (className ? ' ' + className : '')}>
      {children}
    </div>
  );
}
