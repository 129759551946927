import './PageNotFound.sass';

export interface PageNotFoundProps {}

export default function PageNotFound(props: PageNotFoundProps) {
  return (
    <section className="PageNotFound">
      <h2>The page you are looking for is missing!</h2>
    </section>
  );
}
