import { useRef, useState } from 'react';
import './Navigation.sass';
import { IoArrowForward as ArrowIcon } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

const routes = [
  {
    path: '/posts',
    title: 'Yazılar',
    content: 'Tüm yazıları düzenle.',
  },
  {
    path: '/pages',
    title: 'Sayfalar',
    content: 'Tüm sayfaları düzenle.',
  },
  {
    path: '/users',
    title: 'Kullanıcılar',
    content: 'Konuyu anladın gibi.',
  },
];

interface NavigationProps {
  className?: string;
}

export default function Navigation({ className }: NavigationProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [state, setState] = useState({ expanded: false, item: '' });

  const enterCard = (name: string) => {
    setState({ expanded: true, item: name });
  };
  const leaveNavigation = () => {
    setState({ expanded: false, item: '' });
  };

  return (
    <div className="Navigation">
      <div
        ref={ref}
        className="navigation-container"
        onMouseLeave={leaveNavigation}
        onWheel={(e) => {
          if (ref.current) {
            ref.current.scrollLeft += e.deltaY;
          }
        }}
      >
        {routes.map((route) => {
          return (
            <div
              className={`navigation-card-container ${
                state.item === route.path
              }`}
              key={route.path}
            >
              <NavLink exact to={route.path}>
                <div
                  className="navigation-card"
                  onMouseEnter={() => enterCard(route.path)}
                  onClick={() =>
                    console.debug(
                      `@todo will navigate to the ${route.path} route.`
                    )
                  }
                >
                  <h2 className="card-title">{route.title}</h2>
                  <div className="card-content">{route.content}</div>
                  {state.item === route.path && (
                    <div className="card-arrow">
                      <ArrowIcon />
                    </div>
                  )}
                  <div className="container-background" />
                </div>
              </NavLink>
            </div>
          );
        })}
      </div>
    </div>
  );
}
