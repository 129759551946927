import axios from 'axios';
import store from '..';
import Config from 'src/config';

const url = `${Config.api.url}/v1` || 'http://localhost:3333/v1';

export function setAuthToken(token: string) {
  localStorage.setItem('access_token', token);
}

export function getAuthToken() {
  return localStorage.getItem('access_token');
}

export async function authLogin(email: string, password: string) {
  function loginFailed(resolve: (value: unknown) => void) {
    setAuthToken('');
    store.dispatch({ type: 'SET_AUTH', payload: false });
    store.dispatch({ type: 'SET_INIT', payload: false });
    store.dispatch({
      type: 'SET_ALERT',
      payload: { type: 'error', message: 'Login failed!' },
    });
    resolve(false);
  }

  return await new Promise((resolve) => {
    axios
      .post(`${url}/login`, { email, password })
      .then((response) => {
        if (response.data.accessToken && response.data.accessToken.token) {
          const newToken = 'bearer ' + response.data.accessToken.token;
          setAuthToken(newToken);

          setTimeout(() => {
            store.dispatch({ type: 'SET_AUTH', payload: true });
            store.dispatch({ type: 'SET_INIT', payload: false });
            store.dispatch({
              type: 'SET_ALERT',
              payload: { type: 'success', message: '' },
            });
            resolve(true);
          }, 200);
        } else {
          setTimeout(() => loginFailed(resolve), 400);
        }
      })
      .catch((reason) => {
        if (axios.isCancel(reason)) {
          console.debug(reason);
          return;
        }
        setTimeout(() => loginFailed(resolve), 400);
      });
  });
}

export function authVerify() {
  if (!getAuthToken()) {
    store.dispatch({ type: 'SET_AUTH', payload: false });
    store.dispatch({ type: 'SET_INIT', payload: false });
    return;
  }

  const expire = getAuthToken() ? true : false;

  axios
    .post(
      `${url}/login`,
      { withToken: true },
      { headers: { authorization: getAuthToken() } }
    )
    .then((response) => {
      if (response.data.user) {
        setTimeout(() => {
          store.dispatch({ type: 'SET_AUTH', payload: true });
          store.dispatch({ type: 'SET_INIT', payload: false });
        }, 200);
      }
    })
    .catch((reason) => {
      if (axios.isCancel(reason)) {
        console.debug(reason);
        return;
      }

      setTimeout(() => {
        setAuthToken('');
        store.dispatch({ type: 'SET_AUTH', payload: false });
        store.dispatch({ type: 'SET_INIT', payload: false });

        if (expire) {
          store.dispatch({
            type: 'SET_ALERT',
            payload: { type: 'error', message: 'Login expired!' },
          });
        }
      }, 200);
    });
}

export function authLogout() {
  setAuthToken('');
  store.dispatch({ type: 'SET_AUTH', payload: false });
}

export function setPageTitle(title: string) {
  document.title = title + ' | Admin';
  store.dispatch({ type: 'SET_TITLE', payload: title });
}
