import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';
import 'animate.css';
import './App.sass';
import { BottomBar, Loader, Login, Routing, TopBar } from '.';
import { Home, Posts } from 'src/pages';
import { authVerify } from 'src/store/actions/app';
import moment from 'moment';
import 'moment/locale/tr';

document.documentElement.lang = 'tr';
moment.locale('tr');

const routes = [
  {
    label: 'Genel Bakış',
    path: '/',
    component: Home,
    exact: true,
  },
  {
    label: 'Yazılar',
    path: '/posts',
    component: Posts,
    exact: true,
  },
];

function App() {
  const init = useAppSelector((state) => state.app.init);
  const auth = useAppSelector((state) => state.app.auth);

  useEffect(() => {
    authVerify();
  }, []);

  return (
    <Router>
      {init ? (
        <Loader className="flex" />
      ) : !auth ? (
        <>
          <Login />
          <BottomBar />
        </>
      ) : (
        <>
          <TopBar routes={routes} />
          <Routing routes={routes} />
        </>
      )}
    </Router>
  );
}

export default App;
