import { IoClose } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';
import './RouteHeader.sass';

export interface RouteHeaderProps {
  returnTo: string;
  replaceTo?: boolean;
}

export default function RouteHeader({ returnTo, replaceTo }: RouteHeaderProps) {
  const title = useAppSelector((state) => state.routing.title);

  return (
    <section className="RouteHeader">
      <h2 className="heading">{title}</h2>
      <div className="actions">
        <NavLink className="return-to" exact to={returnTo} replace={replaceTo}>
          <IoClose />
        </NavLink>
      </div>
    </section>
  );
}
