import { useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Mainbar, Paper, RouteHeader, Sidebar } from 'src/components';
import { setPageTitle } from 'src/store/actions/app';

export default function Overview(props: RouteComponentProps<{}>) {
  const location = useLocation();

  useEffect(() => {
    setPageTitle('Yazılar');
  }, [location]);

  return (
    <Paper className="page primary full-width full-height posts">
      <Mainbar>
        <RouteHeader returnTo="/" />
        <div className="page-content"></div>
      </Mainbar>
      <Sidebar />
    </Paper>
  );
}
