import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import PageNotFound from './PageNotFound';
import './Routing.sass';

export interface RoutingProps {
  routes: Array<{
    label: string,
    path: string,
    component: React.FunctionComponent<RouteComponentProps>,
    exact?: boolean | undefined,
    visible?: boolean | undefined,
  }>;
}

export default function Routing({ routes }: RoutingProps) {
  return (
    <section className="Routing">
      <Switch>
        {routes.map((route) => (
          <Route
            path={route.path}
            exact={route.exact}
            render={(props) => <route.component {...props} />}
            key={route.path}
          />
        ))}
        <Route path="*" component={PageNotFound} />
      </Switch>
    </section>
  );
}
