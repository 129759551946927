import { useAppSelector } from 'src/hooks';
import type { RoutingProps } from './Routing';
import './TopBar.sass';
import { TopBarNavigation } from '.';

export default function TopBar({ routes }: RoutingProps) {
  const heading = useAppSelector((state) => state.app.heading);

  return (
    <header className="TopBar">
      <div className="branding">
        <h1>{heading}</h1>
      </div>
      <TopBarNavigation />
    </header>
  );
}
