import { useState, useCallback, useRef } from 'react';
import './TopBarNavigation.sass';
import { IoLogOut, IoPerson } from 'react-icons/io5';
import { authLogout } from 'src/store/actions/app';

interface TopBarNavigationProps {}

export default function TopBarNavigation(props: TopBarNavigationProps) {
  const menuRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const closeMenu = useCallback((e: any) => {
    if (menuRef && menuRef.current && !menuRef.current.contains(e.target)) {
      setOpen(false);
      document.removeEventListener('click', closeMenu);
    }
  }, []);

  const openMenu = useCallback(
    (e: any) => {
      document.removeEventListener('click', closeMenu);
      setOpen(true);
      setTimeout(() => {
        document.addEventListener('click', closeMenu);
      });
    },
    [closeMenu]
  );

  const hideMenu = useCallback(() => {
    setOpen(false);
    document.removeEventListener('click', closeMenu);
  }, [closeMenu]);

  return (
    <div className="TopBarNavigation">
      <button
        className={'menu-button' + (open ? ' active' : '')}
        onClick={open ? closeMenu : openMenu}
      >
        <IoPerson className="icon" />
      </button>
      {open && (
        <div ref={menuRef} className="menu">
          <button
            className="item"
            onClick={() => {
              console.debug('@todo will navigate to the user profile page.');
              hideMenu();
            }}
          >
            <IoPerson className="icon" />
            <span>Profile</span>
          </button>
          <button className="item" onClick={authLogout}>
            <IoLogOut className="icon" />
            <span>Logout</span>
          </button>
        </div>
      )}
    </div>
  );
}
