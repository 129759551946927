import { useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Mainbar, Paper, Sidebar } from 'src/components';
import { setPageTitle } from 'src/store/actions/app';
import Navigation from './Navigation';

export default function Overview(props: RouteComponentProps<{}>) {
  const location = useLocation();

  useEffect(() => {
    setPageTitle('Genel Bakış');
  }, [location]);

  return (
    <Paper className="page full-width full-height no-padding overview">
      <Mainbar>
        <Navigation />
      </Mainbar>
      <Sidebar />
    </Paper>
  );
}
