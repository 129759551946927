import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'src/hooks';
import { Loader, Paper } from '.';
import './Login.sass';
import logo from 'src/assets/images/logo.svg';
import { authLogin } from 'src/store/actions/app';

export interface LoginProps {}

export default function Login(props: LoginProps) {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const alert = useAppSelector((state) => state.app.alert);
  const [working, setWorking] = useState(false);

  const loginClicked = useCallback(async () => {
    setWorking(true);

    const email: string = emailRef.current?.value || '';
    const password: string = passwordRef.current?.value || '';

    await authLogin(email, password);

    setWorking(false);
  }, []);

  useEffect(() => {
    return () => {};
  });

  return (
    <div className="Login">
      <Paper className="form-container">
        <div className="logo">
          <img src={logo} alt="App Logo" />
        </div>
        {!working && alert.message && (
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        )}
        <div className="form">
          <input
            disabled={working}
            ref={emailRef}
            type="email"
            placeholder="Email"
            autoFocus
          />
          <input
            disabled={working}
            ref={passwordRef}
            type="password"
            placeholder="Password"
          />
          <div className="button-container">
            <button disabled={working} onClick={loginClicked}>
              Login
            </button>
            {working && <Loader />}
          </div>
        </div>
      </Paper>
    </div>
  );
}
