const initialState = {
  init: true,
  auth: false,
  alert: { type: 'success', message: '' },
  heading: 'Yönetici Paneli',
  title: 'Genel Bakış',
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_INIT':
      state = { ...state, init: action.payload };
      break;
    case 'SET_AUTH':
      state = { ...state, auth: action.payload };
      break;
    case 'SET_ALERT':
      state = { ...state, alert: action.payload };
      break;
    case 'SET_TITLE':
      state = { ...state, title: action.payload };
      break;

    default:
      break;
  }

  return state;
}
