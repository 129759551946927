declare const window: Window & typeof globalThis & { Config: any };

const Config = {
  api: { protocol: '', host: '', url: '' },
};

(function () {
  const [protocol, host] = (() => {
    if (window.Config !== undefined) {
      return [
        window.Config.api.protocol || 'http',
        window.Config.api.host || 'localhost:3333',
      ];
    } else {
      return ['http', 'localhost:3333'];
    }
  })();

  Config.api = { ...Config.api, protocol, host, url: `${protocol}://${host}` };
})();

export default Config;
